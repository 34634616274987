import { UserQueryInfo } from "@/@types/staking/staking.types";
import API_KEYS from "@/constants/apiKeys";
import { queryClient } from "@/lib/react-query";
import { useProtocol } from "@/providers/protocol";
import { addNotification } from "@/stores/notifications";
import { useMutation } from "react-query";

const useWithdrawAirdrops = () => {
  const { contracts } = useProtocol();
  return useMutation(
    async () => {
      return contracts.Staking.execute({
        withdraw_airdrops: {},
      });
    },
    {
      onSuccess: async (data) => {
        if (!data.right) return;

        addNotification({ title: "Withdraw airdrops successful", type: "success" });

        queryClient.setQueryData(
          API_KEYS.STAKING_GET_USER_INFO,
          (prev: UserQueryInfo | undefined) => {
            if (!prev) {
              return {} as UserQueryInfo;
            }
            return {
              ...prev,
              total_airdrops: [],
            };
          }
        );
      },
    }
  );
};

export default useWithdrawAirdrops;
