import exactMath from "./exactMath";

type NumberFormatingOptions = {
  decimal?: number;
};
export const lowerBoundOfUNum = (uluna: number | string, opts?: NumberFormatingOptions) => {
  return exactMath.floor(exactMath.div(Number(uluna), 1000000), opts?.decimal ?? -2);
};

export const upperBoundOfUNum = (uluna: number | string, opts?: NumberFormatingOptions) => {
  return exactMath.ceil(exactMath.div(Number(uluna), 1000000), opts?.decimal ?? -2);
};
