import { UserQueryInfo } from "@/@types/staking/staking.types";
import API_KEYS from "@/constants/apiKeys";
import { queryClient } from "@/lib/react-query";
import { useProtocol } from "@/providers/protocol";
import { addNotification } from "@/stores/notifications";
import exactMath from "@/utils/exactMath";
import { useMutation } from "react-query";

const useUndelegate = (props: MutationProps) => {
  const { contracts } = useProtocol();
  return useMutation(
    async (amount: number) => {
      return contracts.Staking.execute({
        queue_undelegate: { amount: `${amount}` },
      });
    },
    {
      onSuccess: async (data, amount) => {
        if (!data.right) return;

        addNotification({
          title: "Undelegate successful",
          type: "success",
          message: `${amount} uLUNA has been undelegated`,
        });

        queryClient.setQueryData(
          API_KEYS.STAKING_GET_USER_INFO,
          (prev: UserQueryInfo | undefined) => {
            if (!prev) {
              return {} as UserQueryInfo;
            }
            return {
              ...prev,
              total_amount: {
                ...prev.total_amount,
                amount: `${Math.max(exactMath.sub(Number(prev.total_amount.amount), amount), 0)}`,
              },
            };
          }
        );

        if (props?.onSuccess) {
          props.onSuccess();
        }
      },
      onSettled: async () => {
        const fn = () =>
          queryClient.invalidateQueries(API_KEYS.STAKING_GET_UNDELEGATION_RECORDS, { exact: true });
        fn();
        setTimeout(fn, 4000);
      },
    }
  );
};

export default useUndelegate;
