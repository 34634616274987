import Staking from "@/features/staking";
import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

// TODO: can have multiple routes in this directory
// Public private routes
// Nested routes
// etc.,

export const AppRoutes = () => (
  <>
    {useRoutes([
      { path: "/stake", element: <Staking /> },
      { path: "*", element: <Navigate to="./stake" /> },
    ])}
  </>
);
