import DisconnectWallet from "@/components/DisconnectWallet/DisconnectWallet";
import { Button } from "@/components/Elements";
import { Notifications } from "@/components/Notifications/Notifications";
import { queryClient } from "@/lib/react-query";
import ProtocolProvider from "@/providers/protocol";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-full h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Something went wrong!</h2>
      <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </Button>
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const Loader = (
    <div className="flex items-center justify-center w-screen h-screen">
      <ClipLoader />
    </div>
  );

  return (
    <div className="mx-auto max-w-screen-lg relative">
      <React.Suspense fallback={Loader}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== "production" && <ReactQueryDevtools />}
            <Notifications />
            <ProtocolProvider>
              <DisconnectWallet />
              <Router>{children}</Router>
            </ProtocolProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </React.Suspense>
    </div>
  );
};
