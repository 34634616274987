import useGetUndelegationRecords from "@/apis/staking/getUndelegationRecords";
import React from "react";
import UndelegationRecord from "./UndelegationRecord";

const UndelegationRecords = () => {
  const recordsApi = useGetUndelegationRecords();

  if (recordsApi.isLoading) {
    return <p>Loading Withdraws...</p>;
  }

  if (recordsApi.isError) {
    return <p>Error loading withdraws</p>;
  }

  const data = recordsApi.data;

  if (!data) return null;

  return (
    <div className="p-5 m-5 w-3/4 flex flex-col items-center">
      <h1 className="font-bold text-xl mb-2">Undelegation Records</h1>

      <div className="flex flex-row justify-between w-full font-semibold text-lg border-b-2 border-t-2 border-gray-400 py-2 mb-2">
        <div>Amount </div>
        <div>Release time </div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
      </div>
      {data.length === 0 && <p>No undelegations found</p>}

      {data.map((r) => (
        <UndelegationRecord key={r.batch_id} record={r} />
      ))}
    </div>
  );
};

export default UndelegationRecords;
