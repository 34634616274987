import { UndelegationInfo } from "@/@types/staking/staking.types";
import API_KEYS from "@/constants/apiKeys";
import { queryClient } from "@/lib/react-query";
import { useProtocol } from "@/providers/protocol";
import { addNotification } from "@/stores/notifications";
import { useMutation } from "react-query";

const useWithdrawFunds = () => {
  const { contracts } = useProtocol();

  return useMutation(
    async (batchId: number) => {
      return contracts.Staking.execute({
        withdraw_funds_to_wallet: { batch_id: batchId },
      });
    },
    {
      onSuccess: async (data, batchId) => {
        if (!data.right) return;

        addNotification({
          title: "Withdraw funds successful",
          type: "success",
        });

        queryClient.setQueryData(
          API_KEYS.STAKING_GET_UNDELEGATION_RECORDS,
          (prev: Vec<UndelegationInfo> | undefined) => {
            if (!prev) {
              return [];
            }
            return prev.filter((item) => item.batch_id !== batchId);
          }
        );
      },
    }
  );
};

export default useWithdrawFunds;
