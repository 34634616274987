import { UndelegationInfo } from "@/@types/staking/staking.types";
import useGetUndelegationBatchInfo from "@/apis/staking/getUndelegationBatchInfo";
import useWithdrawFunds from "@/apis/staking/withdrawFunds";
import { Button } from "@/components/Elements";
import { lowerBoundOfUNum } from "@/utils/numbers";
import React from "react";
import { BeatLoader } from "react-spinners";

const UndelegationRecord: React.FC<{ record: UndelegationInfo }> = ({ record }) => {
  const useUndelegationInfo = useGetUndelegationBatchInfo(record.batch_id);
  const withdrawApi = useWithdrawFunds();

  const releaseTimeNanos = useUndelegationInfo.data?.est_release_time;
  const releaseTimeMillis = releaseTimeNanos
    ? lowerBoundOfUNum(releaseTimeNanos, { decimal: 0 })
    : null;
  const releaseTimeDate = releaseTimeMillis ? new Date(releaseTimeMillis).toLocaleString() : null;

  return (
    <div className="flex flex-row justify-between  w-full mb-2">
      <div>{lowerBoundOfUNum(record.amount, { decimal: -6 })}</div>
      <div>
        {useUndelegationInfo.isLoading ? (
          <BeatLoader size={8} />
        ) : (
          releaseTimeDate || "Not released yet"
        )}
      </div>
      <div>
        <Button
          onClick={() => withdrawApi.mutate(record.batch_id)}
          isLoading={withdrawApi.isLoading}
          disabled={!releaseTimeMillis || releaseTimeMillis > Date.now() || withdrawApi.isLoading}
          size="sm"
        >
          Withdraw
        </Button>
      </div>
    </div>
  );
};

export default UndelegationRecord;
