import { UserInfoResponse } from "@/@types/staking/staking.types";
import API_KEYS from "@/constants/apiKeys";
import { useProtocol } from "@/providers/protocol";
import { useQuery } from "react-query";

const useGetUserInfo = () => {
  const { contracts, wallet } = useProtocol();
  return useQuery({
    queryKey: API_KEYS.STAKING_GET_USER_INFO,
    queryFn: async () => {
      const r = await contracts.Staking.query<UserInfoResponse>({
        get_user_info: { user_addr: wallet.terraAddress },
      });

      return r.user_info;
    },
    refetchInterval: 1000 * 20,
    retry: (failCount) => failCount < 4,
  });
};

export default useGetUserInfo;
