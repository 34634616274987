import { QueryBatchUndelegationResponse } from "@/@types/staking/staking.types";
import API_KEYS from "@/constants/apiKeys";
import { useProtocol } from "@/providers/protocol";
import { useQuery } from "react-query";

const useGetUndelegationBatchInfo = (batchId: number) => {
  const { contracts } = useProtocol();
  return useQuery({
    queryKey: API_KEYS.STAKING_GET_UNDELEGATION_BATCH_INFO(batchId),
    queryFn: async () => {
      return contracts.Staking.query<QueryBatchUndelegationResponse>({
        batch_undelegation: { batch_id: batchId },
      }).then((r) => r.batch);
    },
    refetchInterval: 1000 * 20,
    retry: (failCount) => failCount < 4,
  });
};

export default useGetUndelegationBatchInfo;
