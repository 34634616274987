import useGetUserInfo from "@/apis/staking/getUserInfo";
import useWithdrawAirdrops from "@/apis/staking/withdrawAirdrops";
import { Button } from "@/components/Elements";
import { lowerBoundOfUNum } from "@/utils/numbers";
import React from "react";
import { PropagateLoader } from "react-spinners";

const Airdrops = () => {
  const userInfo = useGetUserInfo();

  const withdrawAirdropApi = useWithdrawAirdrops();
  return (
    <div className="p-5 m-5 w-3/4 flex flex-col items-center">
      <h1 className="font-bold text-xl mb-2">Airdrops</h1>
      {(() => {
        if (userInfo.isLoading) {
          return <PropagateLoader size={10} />;
        } else if (userInfo.data) {
          const airdrops = userInfo.data.total_airdrops;

          if (airdrops.length === 0) {
            return <p>No airdrops yet!</p>;
          }
          return (
            <>
              <div className="flex flex-row flex-wrap items-center justify-around w-full mb-3">
                {airdrops.map(({ amount, denom }) => (
                  <span key={denom} className="rounded px-4 py-2 bg-gray-100">
                    <span className="font-bold bg-gray-200 rounded-full px-2 py-1">
                      {denom.toUpperCase()}
                    </span>
                    : <span>{lowerBoundOfUNum(amount, { decimal: -6 })}</span>
                  </span>
                ))}
              </div>
              <Button
                onClick={() => withdrawAirdropApi.mutate()}
                isLoading={withdrawAirdropApi.isLoading}
                disabled={
                  withdrawAirdropApi.isLoading ||
                  !airdrops.length ||
                  airdrops.every((a) => Number(a.amount) === 0)
                }
                size="md"
              >
                Withdraw airdrops
              </Button>
            </>
          );
        }
        return null;
      })()}
      <div></div>
    </div>
  );
};

export default Airdrops;
