import useDeposit from "@/apis/staking/deposit";
import useUndelegate from "@/apis/staking/undelegate";
import { Button } from "@/components/Elements";
import React from "react";

const StakeAndUnstake = () => {
  const [amount, setAmount] = React.useState(0.0);
  const undelegateApi = useUndelegate({ onSuccess: () => setAmount(0.0) });
  const depositApi = useDeposit({ onSuccess: () => setAmount(0.0) });

  const ulunaAmount = Math.floor(amount * 1000000);
  return (
    <div className="border shadow rounded-md p-5 m-5 w-1/2 flex flex-col items-center">
      <h1 className="font-semibold text-lg mb-1">Enter an amount and choose to stake or unstake</h1>
      <input
        className="block border-2 border-black rounded-md w-100 h-10 text-lg p-2 w-1/2"
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.valueAsNumber)}
      />
      <div className="flex flex-row justify-evenly w-3/4 mt-3">
        <Button
          onClick={() => depositApi.mutate(ulunaAmount)}
          disabled={depositApi.isLoading || !amount}
          isLoading={depositApi.isLoading}
        >
          Stake
        </Button>

        <Button
          onClick={() => undelegateApi.mutate(ulunaAmount)}
          disabled={undelegateApi.isLoading || !amount}
          isLoading={undelegateApi.isLoading}
        >
          Unstake
        </Button>
      </div>
    </div>
  );
};

export default StakeAndUnstake;
