import { UserQueryInfo } from "@/@types/staking/staking.types";
import API_KEYS from "@/constants/apiKeys";
import { queryClient } from "@/lib/react-query";
import { useProtocol } from "@/providers/protocol";
import { addNotification } from "@/stores/notifications";
import exactMath from "@/utils/exactMath";
import { Coin } from "@terra-money/terra.js";
import { useMutation } from "react-query";
const useDeposit = (props?: MutationProps) => {
  const { contracts } = useProtocol();
  return useMutation(
    async (amount: number) => {
      return contracts.Staking.execute(
        {
          deposit: {},
        },
        [new Coin("uluna", amount)]
      );
    },
    {
      onSuccess: async (data, amount) => {
        if (!data.right) return;

        addNotification({
          title: "Deposit successful",
          message: `${amount} uLUNA has been deposited`,
          type: "success",
        });

        queryClient.setQueryData(
          API_KEYS.STAKING_GET_USER_INFO,
          (prev: UserQueryInfo | undefined) => {
            if (!prev) {
              return {
                total_amount: { denom: "uluna", amount: `${amount}` },
                total_airdrops: [],
                total_shares: "",
              };
            }

            return {
              ...prev,
              total_amount: {
                ...prev.total_amount,
                amount: `${exactMath.add(Number(prev.total_amount.amount), amount)}`,
              },
            };
          }
        );

        if (props?.onSuccess) {
          props.onSuccess();
        }
      },
    }
  );
};

export default useDeposit;
