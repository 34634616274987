import { devnetConfig } from "./config.devnet";
import { mainnetConfig } from "./config.mainnet";
import { testnetConfig } from "./config.testnet";

export const staderConfig = (() => {
  console.log("ENV", process.env.REACT_APP_STAGE);
  if (process.env.REACT_APP_STAGE === "devnet") {
    return devnetConfig;
  } else if (process.env.REACT_APP_STAGE === "testnet") {
    return testnetConfig;
  } else if (process.env.REACT_APP_STAGE === "mainnet") {
    return mainnetConfig;
  } else {
    return testnetConfig;
  }
})();
