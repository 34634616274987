import useGetUserInfo from "@/apis/staking/getUserInfo";
import { lowerBoundOfUNum } from "@/utils/numbers";
import React from "react";
import { BeatLoader, ClockLoader } from "react-spinners";

const UserInfo = () => {
  const userInfo = useGetUserInfo();

  const totalStaked = userInfo.data?.total_amount.amount;

  return (
    <h1 className="text-2xl font-bold">
      Staked amount:{" "}
      {(() => {
        if (userInfo.isLoading) {
          return <BeatLoader size={8} />;
        } else if (userInfo.isError) {
          return "Error";
        } else {
          return (
            <span>
              {userInfo.isFetching && <ClockLoader size={10} />}
              {totalStaked
                ? lowerBoundOfUNum(Number(totalStaked), { decimal: -6 })
                : "Unable to fetch"}
            </span>
          );
        }
      })()}
    </h1>
  );
};

export default UserInfo;
