import { UndelegationInfo } from "@/@types/staking/staking.types";
import API_KEYS from "@/constants/apiKeys";
import { useProtocol } from "@/providers/protocol";
import { useQuery } from "react-query";

const useGetUndelegationRecords = () => {
  const { contracts, wallet } = useProtocol();
  return useQuery({
    queryKey: API_KEYS.STAKING_GET_UNDELEGATION_RECORDS,
    queryFn: async () => {
      return contracts.Staking.query<Vec<UndelegationInfo>>({
        get_user_undelegation_records: { user_addr: wallet.terraAddress },
      });
    },
    refetchInterval: 1000 * 20,
    retry: (failCount) => failCount < 4,
  });
};

export default useGetUndelegationRecords;
