import React from "react";
import Airdrops from "./components/Airdrops";
import StakeAndUnstake from "./components/StakeAndUnstake";
import UndelegationRecords from "./components/UndelegationRecords";
import UserInfo from "./components/UserInfo";

interface IStakingProps {}
const Staking: React.FC<IStakingProps> = () => {
  return (
    <div className="flex items-center flex-col w-100 m-4 gap-10">
      <UserInfo />
      <StakeAndUnstake />
      <UndelegationRecords />
      <Airdrops />
    </div>
  );
};

export default Staking;
