import { useProtocol } from "@/providers/protocol";
import React from "react";
import { Button } from "../Elements";

const DisconnectWallet = () => {
  const { disconnectWallet } = useProtocol();
  return (
    <div className="absolute top-0 right-0">
      <Button size="sm" onClick={disconnectWallet}>
        Disconnect Wallet
      </Button>
    </div>
  );
};

export default DisconnectWallet;
