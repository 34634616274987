import { StakingExecute, StakingQuery } from "@/@types";
import { Button } from "@/components/Elements";
import { staderConfig } from "@/config";
import StaderContract from "@/utils/contract";
import {
  ConnectedWallet,
  ConnectType,
  useConnectedWallet,
  useWallet,
  WalletProvider,
  WalletStatus,
} from "@terra-money/wallet-provider";
import * as React from "react";
import { CircleLoader } from "react-spinners";

type WalletProviderProps = { children: React.ReactNode };

const getContracts = (wallet: ConnectedWallet) => {
  return {
    Staking: new StaderContract<StakingQuery, StakingExecute>(
      staderConfig.contractAddresses.Staking,
      wallet
    ),
  };
};

type StaderContracts = ReturnType<typeof getContracts>;

const ProtocolContext = React.createContext<
  { wallet: ConnectedWallet; contracts: StaderContracts; disconnectWallet: () => void } | undefined
>(undefined);

function WithInitialized({ children }: WalletProviderProps) {
  const wallet = useWallet();
  const connectedWallet = useConnectedWallet();

  const [contracts, setContracts] = React.useState<StaderContracts | undefined>();

  React.useEffect(() => {
    if (connectedWallet) {
      setContracts(getContracts(connectedWallet));
    }
  }, [wallet.status, connectedWallet]);

  if (wallet.status === WalletStatus.INITIALIZING) {
    return (
      <div className="flex flex-row flex-wrap items-center justify-center w-full h-screen">
        <h1 className="font-bold text-2xl mr-2">Initializing wallet</h1>
        <br />
        <CircleLoader />
      </div>
    );
  }

  if (
    !connectedWallet ||
    wallet.wallets.length === 0 ||
    wallet.status === WalletStatus.WALLET_NOT_CONNECTED
  ) {
    return (
      <div className="w-full h-screen flex flex-wrap justify-evenly items-center">
        <Button onClick={() => wallet.connect(ConnectType.CHROME_EXTENSION)}>Connect Wallet</Button>
        <Button>
          <a href={staderConfig.terraStationExtensionURL} target={"_blank"} rel="noreferrer">
            Install Terra Station
          </a>
        </Button>
      </div>
    );
  }

  if (!contracts) {
    return null;
  }

  return (
    <ProtocolContext.Provider
      value={{ wallet: connectedWallet, contracts, disconnectWallet: wallet.disconnect }}
    >
      {children}
    </ProtocolContext.Provider>
  );
}

function ProtocolProvider({ children }: WalletProviderProps) {
  return (
    <WalletProvider
      defaultNetwork={staderConfig.network}
      walletConnectChainIds={{
        0: staderConfig.network,
      }}
    >
      <WithInitialized>{children}</WithInitialized>
    </WalletProvider>
  );
}

export function useProtocol() {
  const context = React.useContext(ProtocolContext);
  if (context === undefined) {
    throw new Error("useProtocol must be used within a ProtocolProvider");
  }
  return context;
}

export default ProtocolProvider;
