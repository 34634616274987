import clsx from "clsx";
import * as React from "react";
import { ClipLoader } from "react-spinners";

const variants = {
  primary: "border-2 border-gray-600 bg-white hover:border-gray-50:bg-gray-600",
  inverse: "border-2 border-white bg-gray-600 hover:border-gray-600:bg-white",
  danger: "border-2 border-red-600 bg-white hover:border-red-50:bg-red-600",
};

const sizes = {
  xs: "px-2 py-1 text-xs",
  sm: "py-1 px-3 text-sm",
  md: "py-2 px-6 text-md",
  lg: "py-3 px-8 text-lg",
};

type IconProps = { startIcon?: React.ReactElement; endIcon?: React.ReactElement };

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  isLoading?: boolean;
} & IconProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = "button",
      className = "",
      variant = "primary",
      size = "md",
      isLoading = false,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          "flex justify-center items-center disabled:opacity-70 disabled:cursor-not-allowed rounded-md shadow-2xl font-medium focus:outline-none",
          variants[variant],
          sizes[size],
          className
        )}
        {...props}
      >
        {isLoading && <ClipLoader color="#000" size={20} />}
        {!isLoading && startIcon}
        <span className="mx-2">{props.children}</span> {!isLoading && endIcon}
      </button>
    );
  }
);

Button.displayName = "Button";
