const stakingAddress = (() => {
  const validatorName = process.env.REACT_APP_VALIDATOR;

  if (validatorName === "dacm") {
    return "terra1wtxc4vfk8r9rdullaqm5euxvqs3javdkyy0pz9";
  } else if (validatorName === "hypersphere") {
    return "terra167szfqgnqpezer5tfzf9f0uqj3lw6t59y2f3ej";
  } else if (validatorName === "accomplice") {
    return "terra1smgqsx87cd9q62pa6mrvmydayxd2jegys3cd2d";
  } else if (validatorName === "pilot") {
    return "terra1jru7kq9wktt7yn4s3wr7lf8vwspqugl09wue3f";
  }

  throw new Error(`Unknown validator name: ${validatorName}`);
})();

export const mainnetConfig = {
  network: {
    name: "mainnet",
    chainID: "columbus-5",
    lcd: "https://lcd.terra.dev",
    walletconnectID: 0,
    fcd: "https://fcd.terra.dev",
    localterra: false,
  },
  contractAddresses: {
    Staking: stakingAddress,
  },
  baseApiURL: "https://apis.staderlabs.com",
  terraStationExtensionURL:
    "https://chrome.google.com/webstore/detail/terra-station/aiifbnbfobpmeekipheeijimdpnlpgpp",
};
